import * as converter from 'json-2-csv';

import { SortTypes } from '../types/pagination';
import { ProjectGeneralFormValuesType } from '../types/projectGeneral';
import { submitEditedEcosystem } from '../pages/Project/Tabs/Ecosystem/contexts/function/ecosystem-delete-function';

const queryData = localStorage.getItem('queryObject');
export const initialState = {
  projects: [],
  counts: {},
  activeProject: void 0,
  queryObject: queryData && queryData.length > 0 ? JSON.parse(queryData) : {},
  loadingProjects: false,
  loadingCreate: false,
  pagination: void 0,
};

export const DEFAULT_IMAGE = {
  id: 'IJ7HG4woGlk',
  url: 'https://images.unsplash.com/photo-1500322095253-b2c00f31fa54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
};

const projects = {
  name: 'projects',
  getReducer: () => {
    return (
      state = initialState,
      { type, payload = {} }: { type: any; payload: any },
    ) => {
      switch (type) {
        case 'GET_PROJECTS_START':
          return {
            ...state,
            loadingProjects: true,
            activeProject: payload.clearActiveProject ? void 0 : state.activeProject,
          };
        case 'GET_PROJECTS_SUCCESS':
          return {
            ...state,
            counts: payload.counts,
            queryObject: payload.queryObject,
            projects:
              payload.pagination.currentPage === 1
                ? payload.projects
                : [...state.projects, ...payload.projects],
            loadingProjects: false,
            pagination: payload.pagination,
          };
        case 'CREATE_PROJECT_START':
          return {
            ...state,
            loadingCreate: true,
          };
        case 'CREATE_PROJECT_END':
          return {
            ...state,
            loadingCreate: false,
          };
        case 'GET_ACTIVE_PROJECT_SUCCESS':
          return {
            ...state,
            activeProject: payload.activeProject,
          };
        default:
          return state;
      }
    };
  },

  doRedirectToProject:
    (id) =>
    ({ store }) => {
      const routeParams = store.selectRouteParams();
      const projectId = id ? id : routeParams.id;
      store.doUpdateUrl(`/projects/${projectId}`);
    },

  doRedirectToProjectEdit:
    (id) =>
    ({ store }) => {
      const routeParams = store.selectRouteParams();
      const projectId = id ? id : routeParams.id;
      store.doUpdateUrl(`/projects/edit/${projectId}`);
    },

  doFetchProjects:
    ({
      sort = SortTypes.DATE,
      page = 1,
      statuses = '',
      tags = '',
      bes = '',
      bus = '',
      technologies = '',
      uctype = '',
      query = '',
      languages = '',
      others = '',
      clearActiveProject = true,
      perPage = 12,
    }) =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: 'GET_PROJECTS_START',
        payload: { clearActiveProject },
      });
      return apiFetch(
        `projects/header?query=${query}&sort=${sort}&page=${page}&statuses=${statuses}&tags=${tags}&bus=${bus}&bes=${bes}&uctype=${uctype}&technologies=${technologies}&languages=${languages}&others=${others}&limit=${perPage}`,
      ).then((response) => {
        const filters = {
          sort,
          page,
          statuses,
          tags,
          bes,
          bus,
          technologies,
          uctype,
          query,
          languages,
          others,
        };

        localStorage.setItem('queryObject', JSON.stringify(filters));

        dispatch({
          type: 'GET_PROJECTS_SUCCESS',
          payload: {
            projects: response.data,
            counts: response.counts,
            pagination: response.pagination,
            queryObject: filters,
          },
        });
      });
    },

  doExportProjects:
    ({
      sort = SortTypes.DATE,
      page = 1,
      statuses = '',
      tags = '',
      bes = '',
      bus = '',
      technologies = '',
      uctype = '',
      query = '',
      languages = '',
      others = '',
    }) =>
    ({ dispatch, apiFetch }) => {
      return apiFetch(
        `projects/export?query=${query}&sort=${sort}&page=${-1}&statuses=${statuses}&tags=${tags}&bus=${bus}&bes=${bes}&uctype=${uctype}&technologies=${technologies}&languages=${languages}&others=${others}&limit=${-1}`,
      ).then((response) => {
        converter.json2csv(response.data).then((value) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([`\uFEFF${value}`]));
          // file name with the current date / time as a prefix to avoid duplicate names
          const date = new Date();
          const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
          link.download = `projects-${dateStr}.csv`;
          link.click();
        });
        // return response.data;
      });
    },

  doFetchProject:
    (id) =>
    ({ apiFetch }) =>
      apiFetch(`projects/${id}/header`),

  doFetchLatestProjects:
    (limit = 1) =>
    ({ apiFetch }) =>
      apiFetch(`projects/header?page=1&limit=${limit}`).then((response) => response.data),

  doFetchLatestProject:
    () =>
    ({ apiFetch }) =>
      apiFetch('projects/header?page=1&limit=1').then((response) => response.data[0]),

  doFetchActiveProject:
    () =>
    ({ dispatch, apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      apiFetch(`projects/${routeParams.id}/header`).then((projectHeader) => {
        const { data_management, data_journey, common_api } = projectHeader;
        dispatch({
          type: 'GET_ACTIVE_PROJECT_SUCCESS',
          payload: {
            activeProject: {
              ...projectHeader,
              data_journey: JSON.parse(data_journey),
              data_management: JSON.parse(data_management),
              common_api: JSON.parse(common_api),
            },
          },
        });
      });
    },

  doFetchProjectHeader:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}/header`);
    },

  doFetchProjectGeneral:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}/general`);
    },

  doFetchProjectEcoSystem:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}/ecosystem`);
    },

  doFetchProjectDataScience:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}/datascience`);
    },

  doFetchProjectImages:
    (query) =>
    ({ apiFetch }) =>
      apiFetch(`search/photos?theme=${query}`),

  doFetchSimilarProject:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}/suggestions/projects/header`);
    },

  doFetchSimilarPosts:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}/suggestions/posts`);
    },

  doSaveProject:
    ({
      name,
      description,
      status,
      members = [],
      date = new Date(),
      tags,
      uctype,
      technologies,
      image,
      contact,
      bus,
      bes,
      language,
      fileImage,
    }) =>
    ({ store, dispatch, apiFetch }) => {
      dispatch({ type: 'CREATE_PROJECT_START' });
      const routeParams = store.selectRouteParams();
      const projectHeaderEndpoint = routeParams.id
        ? `projects/${routeParams.id}/header`
        : 'projects/header';
      const method = routeParams.id ? 'PUT' : 'POST';
      return apiFetch(projectHeaderEndpoint, {
        method: method,
        body: {
          title: name,
          shortDescription: description,
          status,
          startDate: date,
          tags,
          uctype,
          technologies,
          members,
          image: {
            id: image.id ? image.id : DEFAULT_IMAGE.id,
            url: image.url ? image.url : DEFAULT_IMAGE.url,
          },
          contactPoint: contact,
          bus,
          bes,
          language,
          fileImage,
        },
      });
    },

  doUpdateProjectGeneral:
    ({
      elevatorPitch,
      goodToKnow,
      objective,
      business_value,
      prerequisites,
      benefits,
      risks,
      other,
    }: ProjectGeneralFormValuesType) =>
    ({ apiFetch, store }) => {
      const projectId = store.selectActiveProject().id;
      return apiFetch(`projects/${projectId}/general`, {
        method: 'PUT',
        body: {
          elevatorPitch,
          goodToKnow,
          objective,
          business_value: {
            scoping: String(business_value.scoping),
            pilot: String(business_value.pilot),
            industrialization: String(business_value.industrialization),
          },
          prerequisites,
          benefits,
          risks,
          other,
        },
      });
    },

  doStoreProjectEcoSystem:
    (payload) =>
    ({ apiFetch, store }) => {
      const projectId = store.selectActiveProject().id;
      return apiFetch(`projects/${projectId}/ecosystem`, {
        method: 'POST',
        body: {
          projectID: projectId,
          payload,
        },
      });
    },

  doResetProjectForm:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: 'CREATE_PROJECT_END',
      });
    },

  doDeleteProject:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`projects/${routeParams.id}`, {
        method: 'DELETE',
      }).then(() => {
        submitEditedEcosystem(routeParams.id).then(() => {
          store.doUpdateUrl('/projects');
        });
      });
    },

  selectProjects: (state) => state.projects.projects,
  selectProjectsCount: (state) => state.projects.counts,
  selectProjectsPagination: (state) => state.projects.pagination,
  selectProjectQueryObject: (state) => state.projects.queryObject,
  selectActiveProject: (state) => state.projects.activeProject,
  selectIsLoadingProjects: (state) => state.projects.loadingProjects,
  selectIsLoadingCreate: (state) => state.projects.loadingCreate,
  selectIsProjectEditing: (state) => state.url.url.includes('edit'),
  selectIsError: (state) => state.projects.isError,
};

export default projects;
