const newsletter = {
  name: 'newsletter',
  doSendTestDigest:
    () =>
    ({ apiFetch }) => {
      return apiFetch('admin/digest-test', {
        method: 'POST',
        body: {
        },
      });
    },

  doSendProdDigest:
    () =>
    ({ apiFetch }) => {
      return apiFetch('admin/digest-prod', {
        method: 'POST',
        body: {
        },
      });
    },
};
export default newsletter;
